import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import illustration from '../../../resources/lottie-animations/content.json';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnimatedIllustration = makeShortcode("AnimatedIllustration");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnimatedIllustration data={illustration} css={{
      marginBottom: 32
    }} mdxType="AnimatedIllustration" />
    <h1 {...{
      "id": "welcome-to-the-ux-content-style-guide"
    }}>{`Welcome to the UX Content Style Guide`}</h1>
    <blockquote>
      <p parentName="blockquote">{`"Don’t use big words. They mean so little.” `}<br />{` -Oscar Wilde`}</p>
    </blockquote>
    <p>{`Writing button language, error messages, dialogs, tooltips, hint text, or something else in the UI?
You’ve come to the right place. From Voice and Tone to Style Standards, we encourage you to use the
information in this guide to help you along the way.`}</p>
    <p>{`This guide is just that, a set of guidelines, not rules. Consider your user’s needs, first and
foremost, and use your judgment as you consult the guidelines.`}</p>
    <p>{`If you’re writing any marketing material (think customer-facing media, newsletters, emails, etc.)
feel free to visit our `}<em parentName="p">{`Branding Guidelines`}</em>{`.`}</p>
    <h3 {...{
      "id": "audience"
    }}>{`Audience`}</h3>
    <p>{`We wrote this guide for Workday designers, product managers, developers, and technical writers to
help them write anything that appears in the Workday product. However, we encourage anyone to use
our guide who intends to write (or review writing) in Workday.`}</p>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h2>
    <h3 {...{
      "id": "voice-and-tone"
    }}>{`Voice and Tone`}</h3>
    <p>{`This section covers how we want users to hear us. It's the character or personality that we want to
portray to our audience.`}</p>
    <h3 {...{
      "id": "language-and-style"
    }}>{`Language and Style`}</h3>
    <p>{`Here you’ll find specifics on grammar used in the UI, from how to use “my” vs. “your” to when to use
capitalization.`}</p>
    <h3 {...{
      "id": "numbers"
    }}>{`Numbers`}</h3>
    <p>{`In this section, you'll see how to consistently format everything related to numbers, from ages to
dates.`}</p>
    <h3 {...{
      "id": "ui-text"
    }}>{`UI Text`}</h3>
    <p>{`This section addresses product copy (the words we use to direct our users - button text, error
messages, tooltips, and so on).`}</p>
    <h2 {...{
      "id": "tips-and-tricks"
    }}>{`Tips and Tricks`}</h2>
    <h3 {...{
      "id": "the-content-checklist"
    }}>{`The Content Checklist`}</h3>
    <p>{`Think of this as your toolbox for any new content you add to your design. Go through this checklist
once you write your content, and before you ask for help from the Content team.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Did you read it out loud?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Is this how you would say it to a friend?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Are there words you can cut?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Is it in the proper capitalization (title, sentence)?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Can you be more specific in any way?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Has an uninvolved colleague read your copy, and do they understand it?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Did you define a new term that could be included in the Content Style Guide?`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "tips-for-designing-with-content-on-the-brain"
    }}>{`Tips for Designing with Content on the Brain`}</h3>
    <p>{`Below are some things to keep in mind during your design process, especially when you’re first
brought into a project and meeting with the stakeholders early on.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Consider what existing pages, task flows, and fields will change from the existing design (if
there is one), and how it’ll affect the content.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Prepare any error messages or notifications that will need to be written.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Think about how page headers and button labels can help improve the clarity of the user
experience.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Investigate if the content in the workflow shows up in other parts of the product. Make sure to
stay consistent with existing content, or notify others if you make a change to improve it.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      